/* .cookie-consent-wrapper {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    height: 80px;
    background-color: #10ffde;
    z-index: 2000;
    padding: 15px;
    font-weight: bolder;
    border-top: 5px solid #b6af8d;
} */

.cookie-verbiage {
    font-size: 1.1rem;
    justify-content: center !important;
}

.cookie-consent-wrapper {
    z-index: 10000;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.95);
    color: white;
    padding-top: 50px;
    padding-right: 20px;
    padding-left: 20px;
}

.cookie-header {
    color: white !important;
}

